import React, { useEffect, useState } from "react";

const InspectionType = ({ index }) => {
  const [value, setValue] = useState("Baustellenbegehung");
  const [resetButtonVisible, setResetButtonVisible] = useState(false);
  useEffect(() => {
    localStorage.setItem("inspektionsart", JSON.stringify(value));
  }, [value]);

  useEffect(() => {
    if (value !== "") {
      setResetButtonVisible(true);
    } else {
      setResetButtonVisible(false);
    }
  }, [value]);
  return (
    <div
      className="card"
      style={{
        marginTop: 10,
        padding: 10,
      }}
    >
      <label>{"Art der Begehung"}</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="Baustellenbegehung"
          value={"Baustellenbegehung"}
          name={"inspektionsart"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          defaultChecked
        />
        <label htmlFor="Baustellenbegehung" className="form-check-label">Baustellenbegehung</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="Betriebsbegehung"
          value={"Betriebsbegehung"}
          name={"inspektionsart"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <label htmlFor="Betriebsbegehung" className="form-check-label">Betriebsbegehung</label>
      </div>
    </div>
  );
};

export default InspectionType;
