import React, { useEffect, useState } from "react";

const EventHeader = ({ index }) => {
    const eventArray = [
      "gefahrdung",
      "festgestellter_mangel",
      "gewerksübergreifend",
      "ort",
      "verursachtdurch",
      "gewerk",
      "schutzmassnahme",
      "einstufung",
    ];
    const [toggle, setToggle] = useState(false);
    useEffect(() => {
      if (toggle) {
        document.getElementById("eventTitle" + index).innerHTML = JSON.parse(
          localStorage.getItem("gefahrdung" + index)
        );
        fadeOutEffect();
      } else {
        fadeInEffect();
      }
    }, [toggle]);
  
    function fadeOutEffect() {
      var fadeTarget = document.getElementById("eventholder" + index);
      var fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
          fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
          fadeTarget.style.opacity -= 0.4;
        } else {
          clearInterval(fadeEffect);
          fadeTarget.style.display = "none";
        }
      }, 50);
    }
  
    function fadeInEffect() {
      var fadeTarget = document.getElementById("eventholder" + index);
      fadeTarget.style.display = "block";
      fadeTarget.style.opacity = 1;
    }
    return (
      <>
        <div
          style={{
            height: 60,
            margin: -10,
            padding: 10,
          }}
        >
          <div className="row">
            <div className="col d-flex flex-row align-items-center">
              <button
                onClick={() => setToggle(!toggle)}
                className="btn btn-link"
                style={{ border: "none" }}
              >
                {toggle ? (
                  <i className="fa-solid fa-angle-right"></i>
                ) : (
                  <i className="fa-solid fa-angle-down"></i>
                )}
              </button>
            </div>
            <div
              onClick={() => setToggle(!toggle)}
              className="col d-flex justify-content-center align-items-center"
            >
              <label id={"eventTitle" + index}></label>
            </div>
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-light"
                onClick={() => {
                  document.getElementById("event" + index).remove();
                  for (let x = 0; x < eventArray.length; x++) {
                    localStorage.removeItem(eventArray[x] + index);
                  }
                }}
              >
                <i className="fa-solid fa-trash-can"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  

export default EventHeader
