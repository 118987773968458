import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import CameraView from "../src/components/CameraView";
import Gallery from "./page/Gallery";
localStorage.clear();
const photos = [];
localStorage.setItem("photos", JSON.stringify(photos));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/camera" element={<CameraView />} />
      <Route path="/gallery/:index" element={<Gallery />} />
    </Routes>
  </BrowserRouter>
);
