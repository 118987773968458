import React, { useEffect, useState } from "react";
import SeperatorHeading from "./SeperatorHeading";
import FormField from "./FormField";
import Events from "./Events";
import { saveAs } from "file-saver";
import Modal from "react-modal";
import { Document, pdfjs } from "react-pdf";
import { Page } from "react-pdf";
import { v4 as uuidv4 } from "uuid";
import InspectionType from "./InspectionType";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Form = () => {
  const [eventList, setEventList] = useState([]);
  const [index, setIndex] = useState(eventList.length);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pdf, setPdf] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [sendingData, setSendingData] = useState(false);
  const [projectUUID, setProjectUUID] = useState(uuidv4());

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function openModal() {
    setModalIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const onSubmit = () => {
    setSendingData(true);
    const projekt = JSON.parse(localStorage.getItem("projekt"));
    const inspektionsart = JSON.parse(localStorage.getItem("inspektionsart"));
    const auftraggeber = JSON.parse(localStorage.getItem("auftraggeber"));
    const bereich = JSON.parse(localStorage.getItem("bereich"));
    const begehungsdatum = JSON.parse(localStorage.getItem("begehungsdatum"));
    const begehungdurch = JSON.parse(localStorage.getItem("begehungdurch"));
    const teilnehmer = JSON.parse(localStorage.getItem("teilnehmer"));
    const sonstige = JSON.parse(localStorage.getItem("sonstige"));
    const anmerkung = JSON.parse(localStorage.getItem("anmerkung"));
    const email = JSON.parse(localStorage.getItem("email"));
    let incidents = [];
    for (let i = 0; i < index; i++) {
      if (localStorage.getItem("gefahrdung" + i) !== null) {
        const gefahrdung = JSON.parse(localStorage.getItem("gefahrdung" + i));
        if (gefahrdung === "") {
          alert("Bitte erwähnen Sie die Gefahrdung");
          setSendingData(false);
          return;
        }
        const festgestellter_mangel = JSON.parse(
          localStorage.getItem("festgestellter_mangel" + i)
        );
        const gewerksübergreifend = JSON.parse(
          localStorage.getItem("gewerksübergreifend" + i)
        );
        const ort = JSON.parse(localStorage.getItem("ort" + i));
        const verursachtdurch = JSON.parse(
          localStorage.getItem("verursachtdurch" + i)
        );
        const gewerk = JSON.parse(localStorage.getItem("gewerk" + i));
        const schutzmassnahme = JSON.parse(
          localStorage.getItem("schutzmassnahme" + i)
        );
        const einstufung = JSON.parse(localStorage.getItem("einstufung" + i));
        var photos = JSON.parse(localStorage.getItem("photos" + i));

        incidents.push({
          gefahrdung,
          festgestellter_mangel,
          gewerksübergreifend,
          ort,
          verursachtdurch,
          gewerk,
          schutzmassnahme,
          einstufung,
          photos,
        });
      }
    }
    const formData = {
      inspektionsart,
      projekt,
      auftraggeber,
      bereich,
      begehungsdatum,
      begehungdurch,
      teilnehmer,
      sonstige,
      anmerkung,
      email,
      incidents,
    };
    console.log(formData);
    requestMaker(formData);
  };

  const requestMaker = (formData) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData),
    };

    fetch(
      "https://sifar-dev.compliance.monster/api/undetailed_report",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setFormSubmitted(true);
        setPdf(result);
        setSendingData(false);
      })
      .catch((error) => console.log("error", error));
  };

  const savePdf = () => {
    var blob = b64toBlob(pdf, "application/pdf");
    setPdfURL(URL.createObjectURL(blob));
    saveAs(
      blob,
      JSON.parse(localStorage.getItem("projekt")) + "_" + projectUUID
    );
    // openModal();
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  return (
    <div>
      <SeperatorHeading heading={"Angaben zur Begehung"} />
      <InspectionType />
      <FormField label={"Projekt (optional)"} id={"projekt"} type={"text"} />
      <FormField
        label={"Auftraggeber / Kunde"}
        id={"auftraggeber"}
        type={"text"}
      />
      <FormField label={"Bereich (optional)"} id={"bereich"} type={"text"} />
      <FormField label={"Begehungsdatum"} id={"begehungsdatum"} type={"date"} />
      <FormField label={"Begehung durch"} id={"begehungdurch"} type={"text"} />
      <FormField label={"Teilnehmer"} id={"teilnehmer"} type={"text"} />
      <FormField
        label={"Sonstige Hinweise (Bemerkungen)"}
        id={"sonstige"}
        type={"text"}
      />
      <TextFieldBig />
      <FormField
        label={"Email"}
        id={"email"}
        type={"email"}
      />
      <label className="text-muted" style={{fontSize:12}}>(Komma verwenden, um weitere E-Mails hinzuzufügen)</label>
      <SeperatorHeading heading={"Angaben zum Mangel"} />
      {/* {eventList} */}
      {Array(index)
        .fill()
        .map((data, i) => {
          return <Events index={i} key={i} />;
        })}

      {formSubmitted ? (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <button
            className="btn btn-success shadow"
            style={{ width: "100%" }}
            onClick={() => savePdf()}
          >
            <i class="fa-solid fa-download"></i> Bericht herunterladen
          </button>
        </div>
      ) : (
        <></>
      )}
      <div
        className="d-flex justify-content-center"
        style={{ flexDirection: "row" }}
      >
        <button
          className="btn btn-outline-primary"
          style={{ marginTop: 10, width: "100%" }}
          onClick={() => {
            setIndex(index + 1);
          }}
        >
          + Mängel
        </button>
        {index === 0 ? (
          <></>
        ) : (
          <button
            className="btn btn-primary shadow"
            style={{
              marginTop: 10,
              marginLeft: 10,
              width: "100%",
            }}
            onClick={() => {
              onSubmit();
            }}
          >
            {sendingData ? "Bericht erstellen" : "Abschließen"}
          </button>
        )}
      </div>

      <div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button className="btn btn-outline-info" onClick={() => closeModal()}>
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <Document file={pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </Modal>
      </div>
    </div>
  );
};

const TextFieldBig = () => {
  const [value, setValue] = useState("");
  useEffect(() => {
    localStorage.setItem("anmerkung", JSON.stringify(value));
  }, [value]);
  return (
    <div className="card" style={{ marginTop: 10 }}>
      <label style={{ padding: 10 }} htmlFor={"anmerkung"}>
        {"Anmerkung zur Begehung"}
      </label>
      <textarea
        className="form-control"
        style={{ border: "none" }}
        id={"anmerkung"}
        placeholder={""}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        rows="3"
      ></textarea>
    </div>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
  },
};

export default Form;
