import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Gallery from "../page/Gallery";
import AddPhoto from "./AddPhoto";

Modal.setAppElement("#root");
const PhotoHolder = ({ index }) => {
  const [photos, setPhotos] = useState([]);
  const [toggleCapture, setToggleCapture] = useState(false);

  useEffect(() => {
    if (toggleCapture) {
      // console.log(JSON.parse(localStorage.getItem("photos"))[index]);
      setPhotos(JSON.parse(localStorage.getItem("photos"+index)));
    }
    setToggleCapture(false);
  }, [toggleCapture]);

  return (
    <>
      <label>
        Anhänge{" "}
        <u>
          <i style={{ fontSize: 12 }}>Bitte fügen Sie hier Bilder an. </i>
        </u>
      </label>
      <div className="row" style={{paddingLeft:10, paddingRight:10}}>
        {photos.map((photo, key) => {
          return photo == index ? (
            <></>
          ) : (
            <PhotoComponent
              setToggleCapture={setToggleCapture}
              image={photo}
              index={index}
              albumKey={key}
            />
          );
        })}
        <AddPhoto setToggleCapture={setToggleCapture} index={index} />
      </div>
    </>
  );
};

const PhotoComponent = ({ image, index, albumKey, setToggleCapture }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  function openModal() {
    setModalIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <>
      <div
        key={albumKey}
        onClick={() => {
          openModal();
        }}
        className="col-3"
        style={{
          marginRight: 5,
          marginTop: 5,
          height: 200,
          background: `url(${image.photo})`,
          backgroundPosition:'center',
          backgroundSize: "cover",
          WebkitBackgroundSize: "cover",
        }}
      >
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Gallery
          photo={image}
          index={index}
          closeModal={closeModal}
          albumKey={albumKey}
          setToggleCapture={setToggleCapture}
        />
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    minHeight:'80vh'
  },
};

export default PhotoHolder;
