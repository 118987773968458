import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Form from "./components/Form";

const App = () => {
  const [onTop, setOnTop] = useState(true);
  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    if (
      document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0
    ) {
      // document.getElementById("navbar").style.padding = "30px 10px";
      // document.getElementById("logo").style.fontSize = "25px";
      setOnTop(false);
    } else {
      // document.getElementById("navbar").style.padding = "80px 10px";
      // document.getElementById("logo").style.fontSize = "35px";
      setOnTop(true);
    }
  }
  return (
    <>
      {onTop ? <Header requiredHeight={170} /> : <Header requiredHeight={60} />}

      <div className="container" style={{ paddingBottom: 50 }}>
        <Form />
      </div>
    </>
  );
};

export default App;
