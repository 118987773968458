import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import back from "../assets/images/back.png";
import header from "../assets/images/header.png";
const Header = ({ requiredHeight = 170 }) => {
  const [opacityVal, setOpacityVal] = useState(1);

  return (
    <nav
      id="navbar"
      className={requiredHeight === 170 ? "navbar" : "navbar sticky-top"}
      style={{
        height: requiredHeight,
        backgroundImage:
          requiredHeight === 170 ? `url(${back})` : `url(${header})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundColor:
          requiredHeight === 170 ? "transparent" : "rgba(255, 255, 255, 0.8)",
        boxShadow:
          requiredHeight === 170 ? "" : "0 4px 30px rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(6.5px)",
        WebkitBackdropFilter: "blur(6.5px)",
        transition: "0.5s",
        border:
          requiredHeight === 170 ? "" : "1px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <div className="container-fluid d-flex justify-content-center">
        <span className="navbar-brand">
          <img
            id="logo"
            src={logo}
            style={{
              height: requiredHeight > 60 ? 30 : 20,
              transition: "height 1.3s",
            }}
            // height={requiredHeight > 60 ? 30 : 20}
            className="d-inline-block align-text-top"
          />
        </span>
      </div>
    </nav>
  );
};

export default Header;
